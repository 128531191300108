import { useMemo } from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { useContentBlocks } from "../../ContentBlock/useContentBlocks";

export function useRowMode() {
  const { numberOfColumns, repositionedBlocksWithRender, isDragging } =
    useDragAndDropContext();

  return useMemo(() => {
    return isRowMode({
      isDragging,
      repositionedBlocksWithRender,
      numberOfColumns,
    });
  }, [isDragging, numberOfColumns, repositionedBlocksWithRender]);
}

export function isRowMode({
  isDragging,
  repositionedBlocksWithRender,
  numberOfColumns,
}: {
  isDragging: Set<string>;
  repositionedBlocksWithRender: ReturnType<
    typeof useContentBlocks
  >["repositionedBlocksWithRender"];
  numberOfColumns: number;
}) {
  if (isDragging.size === 0) return false;

  const firstID = isDragging.values().next().value;
  const firstItemWidth = repositionedBlocksWithRender.find(
    (b) => b.block.DOMElementId === firstID
  )?.block.span_columns;

  return (firstItemWidth ?? 1) >= numberOfColumns;
}
